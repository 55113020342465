<template>
  <div class="search-container-mobile ml-md-5">
    <v-card color="grey lighten-3" :elevation="focused ? 3 : 0" class="pa-0" >
      <v-card-title class="headline bg-loop white--text">
        Search Meetable!!!
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          @click="closeModal"
        >
          Close
        </v-btn>
      </v-card-title>

      <div class="grey lighten-3 pa-2">

      <v-row no-gutters align="center" justify="space-between" class="">
        <v-col>
          <!-- <v-autocomplete
            ref="search"
            class="search mb-2"
            background-color="white"
            :placeholder="searchKind === 'skills' ? 'Baking, Yoga, Presentations, etc..' : 'Name, Skills, Company, etc.'"
            prepend-inner-icon="mdi-magnify"
            solo
            dense flat
            hide-details="true"
            v-model="model"
            cache-items
            return-object
            :hide-no-data="true"
            :items="items"
            :search-input.sync="search"
            :item-text="searchKind === 'people' ? 'fullName' : 'name'"
            item-value="objectID"
            item-color="primary"
            :loading="isLoading"
            >

            <template v-slot:item="{item, on , attrs}">
              <template v-if="searchKind === 'people'">
                <v-list-item-avatar>
                  <img :src="item.thumb">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{item.fullName}}</v-list-item-title>
                  <v-list-item-subtitle v-html="item.skills.join(', ')"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>

            <template v-slot:selection="data">
              {{searchKind === 'people' ? data.item.fullName : data.item.name}}

            </template>
          </v-autocomplete> -->
          <v-text-field
            solo
            ref="search"
            :placeholder="searchKind === 'skills' ? 'Baking, Yoga, Presentations, etc..' : 'Name, Skills, Company, etc.'"
            prepend-inner-icon="mdi-magnify"
            :search-input.sync="search">
          </v-text-field>
        </v-col>
      </v-row>

        <!-- <v-btn-toggle v-model="searchKind" color="blue" dense class="search-buttons pa-1 grey lighten-3">
          <v-btn class="" value="skills">
            Skills
          </v-btn>
          <v-btn value="people">
            People
          </v-btn>
        </v-btn-toggle> -->
      </div>
    </v-card>

  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
export default {
  name: 'searchMobile',
  // props:['initVal', 'initField','placeholder', 'kind'],
  watch:{
    model (val) {
      if (val != null) {
        if (this.searchKind === 'people'){
          if(this.$router.currentRoute.name === 'User'){
            this.$router.push({ name: 'User', params: { userName: val.userName } }).catch(()=>{});
            this.$emit('close-modal', true);
          }
          else{
            this.$router.push({ name: 'User', params: { userName: val.userName } }).catch(()=>{});
            this.$emit('close-modal', true);
          }
        }
      }

    },
    search (query) {
      let vThis = this;
        // Items have already been loaded
        // if (this.items.length > 0) return
        vThis.isLoading = true

        process.env.NODE_ENV === "development" ? console.log( 'search query', query ) : null;
        vThis.index.search(query, vThis.requestOptions)
        .then(({ hits }) => {
          console.log(hits);
          vThis.items = hits;
          vThis.isLoading = false;
        });
      },
      searchKind (val){
        this.setupSearch(val)
      }
  },
  data: () => ({
    model :null,
    search:null,
    items: [],
    isLoading:false,
    index: null,
    client:null,
    requestOptions: null,
    focused:false,
    selects:['skills','people'],
    searchKind: 'skills'
  }),
  methods:{
    onFocus(){
      process.env.NODE_ENV === "development" ? console.log( event ) : null;
      this.focused = !this.focused;
    },
    setupSearch(val){
      this.client = algoliasearch('P1WZL4Y0AZ', 'dfa6463422ca736108276061d8783e71');
      if(val === 'people'){
        this.index = this.client.initIndex('prod_PEOPLE');
        // this.requestOptions = {
        //   headers: { 'X-Algolia-UserToken': 'user123' }
        // }
      }
      else{
        this.index = this.client.initIndex('prod_SKILLS');
      }
    },
    closeModal(){
      this.$store.dispatch('launchSearch',false)
    }
  },
  mounted(){
    this.setupSearch('people');
    let autocompleteInput = this.$refs.search.$refs.input
     autocompleteInput.addEventListener('focus', this.onFocus, true)
    autocompleteInput.addEventListener('blur', this.onFocus, true)
  }
};




</script>


<style lang="scss">
.search-container-mobile{
  width:100%;
  .v-card{
    border-radius: 0!important;
  }
  .search{
    .v-input__append-inner{
      display: none!important;
    }
  }
  .search-buttons{
    width:100%;
    > .v-btn{
      width:50%;
    }
  }
  .search-kind{
    width:100px;

    .v-input__slot{
      padding-right: 4px!important;
      border-top-left-radius:0!important;
      border-bottom-left-radius:0!important;
    }
  }
}
</style>
